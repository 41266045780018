<template>
  <div class="container" ref="container"></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { sleep } from "@/utils/common";
export default {
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      clock: null,
      height: 800,
      width: 450,

      model: null, // 模型
      mixer: null, // 加载动画
      actions: {}, // 模型所有动画
      activeAction: null,
      previousAction: null,
      states: ["Idle", "Walking", "Running", "Dance", "Death", "Sitting", "Standing"],
      activeStates: "Running",
      emotes: ["Jump", "Yes", "No", "Wave", "Punch", "ThumbsUp"],

      randomStates: [
        "Walking",
        "Running",
        "Dance",
        "Sitting",
        "Standing",
        "Jump",
        "Yes",
        "No",
        "Wave",
        "Punch",
        "ThumbsUp",
      ],
    };
  },
  mounted() {
    this.init();
    this.animate();
    this.run();
  },
  methods: {
    //初始化
    init() {
      //  创建场景对象Scene
      this.scene = new THREE.Scene();
      this.clock = new THREE.Clock();

      const that = this;

      // lights
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 20, 0);
      this.scene.add(hemiLight);

      const dirLight = new THREE.DirectionalLight(0xffffff);
      dirLight.position.set(-30, 20, 10);
      this.scene.add(dirLight);

      //网格模型添加到场景中
      const loader = new GLTFLoader();
      loader.load(
        "models/DH/BD/test1.glb",
        // "models/gltf/RobotExpressive/RobotExpressive.glb",
        function (gltf) {
          console.log("gltf", gltf);
          that.model = gltf.scene;
          that.model.scale=new THREE.Vector3( 8, 8, 8 )
          that.model.visible = false;
          that.scene.add(that.model);

          that.getAnimation(that.model, gltf.animations);
        },
        undefined,
        function (e) {
          console.error(e);
        }
      );

      /**
       * 相机设置
       */
      let container = this.$refs["container"];
      this.camera = new THREE.PerspectiveCamera(45, this.width / this.height, 0.1, 100);
      this.camera.position.set(-30, 0, 0);
      this.camera.lookAt(new THREE.Vector3(0, 2, 0));

      /**
       * 创建渲染器对象
       */
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(this.renderer.domElement);

      //创建控件对象
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    },

    // 动画
    animate() {
      const dt = this.clock.getDelta();
      if (this.mixer) this.mixer.update(dt);

      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    // 获取动画
    getAnimation(model, animations) {
      this.mixer = new THREE.AnimationMixer(model);

      for (let i = 0; i < animations.length; i++) {
        const clip = animations[i];
        const action = this.mixer.clipAction(clip);
        this.actions[clip.name] = action;
      }
    },
    // 设置动画
    setAnimation(name) {
      this.fadeToAction(name, 0.2);
      this.mixer.addEventListener("finished", this.restoreState);
    },
    // 执行动画
    fadeToAction(name, duration) {
      this.previousAction = this.activeAction;
      this.activeAction = this.actions[name];
      console.log(this.actions,name,this.activeAction);

      if (this.previousAction != undefined && this.previousAction !== this.activeAction) {
        this.previousAction.fadeOut(duration);
      }

      console.log("this.activeAction", this.activeAction);
      this.activeAction.reset().setEffectiveTimeScale(1).setEffectiveWeight(1).fadeIn(duration).play();
    },
    restoreState() {
      this.mixer.removeEventListener("finished", this.restoreState);
      this.fadeToAction(this.activeStates, 0.2);
    },
    async run() {
      await sleep(3000);
      this.model.visible = true;

      this.setAnimation("Running");
      await sleep(3000);

      this.setAnimation("Idle");
      await sleep(3000);

      this.setAnimation("Running");
      await sleep(3000);

      this.setAnimation("Talk");
      await sleep(3000);

      this.setAnimation("Walking");
      await sleep(3000);

      this.setAnimation("Waving");
      await sleep(3000);

      this.setAnimation("Idle");

//       Idle
// Running
// Talk
// Walk
// Walking


    },
  },
};
</script>

<style>
.container {
  width: 450px;
  height: 800px;
}
</style>
